import React, { useState, useEffect } from "react";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import AudioFileIcon from "@mui/icons-material/AudioFile";
import MoneyOffIcon from "@mui/icons-material/MoneyOff"; // Corrected import for MoneyOffIcon
import {
  uploadImage,
  uploadAudio,
  upload_image_api,
} from "src/DAL/uploadFiles";
import { useSnackbar } from "notistack";
import DatePickerField from "src/components/Datepicker";
import {
  Drawer,
  Button,
  TextField,
  Container,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Grid,
  Alert,
  AlertTitle,
  ToggleButton,
  ToggleButtonGroup,
  Switch,
  CircularProgress,
} from "@mui/material";
import { supportedLanguages } from "src/constants";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAppContext } from "src/hooks";
import moment from "moment";
import { addStory, videoUploadApi } from "src/DAL/story";
import { Box } from "@material-ui/core";
import { TextFields } from "@mui/icons-material";

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 5;
const MenuProps = {
  PaperProps: {
    style: {
      width: 200,
      minHeight: "50px",
      maxHeight: "500px",
      overflowY: "auto",
    },
  },
};

function AddTrackDrawer({
  openAddDrawer,
  setOpenAddDrawer,
  categories,
  showAddedDataWithoutApi,
  tracks,
  setTracks,
  count,
  setCount,
  funcTracks,
}) {
  const { checked } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState();
  const [author, setAuthor] = useState();
  const [selectedImage, setSelectedImage] = useState("");
  const [storyImage, setStoryImage] = useState();
  const [selectedAudio, setSelectedAudio] = useState();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState();
  const [isPaid, setIsPaid] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationDescription, setNotificationDescription] = useState();
  const [segment, setSegment] = useState("day");
  const [mediaType, setMediaType] = useState("image");
  const [videoLink, setVideoLink] = useState("");
  const [selectedVideo, setSelectedVideo] = useState();
  const [language, setLanguage] = useState([]);

  const [storytype, setStoruttype] = useState("");
  const [description, setDescription] = useState("");
  const [videoOption, setVideoOption] = useState("custom");
  const [urlLink, setUrlLink] = useState("");

  const [videourl, setVideoUrl] = useState("");
  const [videothumbnail, setVideothumbnail] = useState("");
  const [status, setStatus] = useState(true);
  const [storycatogeries, setStorycatogeries] = useState([]);
  const [selectedThumbnail, setSelectedThumbnail] = useState("");
  const [thumbnailPreview, setThumbnailPreview] = useState("");
  const [storyImagePreview, setStoryImagePreview] = useState("");
  const [switchNotifications, setSwitchNotifications] = useState(false);
  const [notificationSwitchTitle, setNotificationSwitchTitle] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [notificationDate, setNotificationDate] = useState(
    new Date(moment().add(5, "minutes"))
  );
  const [youtubeLink, setYoutubeLink] = useState("");

  const handleNotificationSwitch = (event) => {
    setSwitchNotifications(event.target.checked);
  };

  const handleMediaTypeChange = (event, newMediaType) => {
    if (newMediaType !== null) {
      setMediaType(newMediaType);
    }
  };
  const handleYoutubeLinkChange = (event) => {
    setYoutubeLink(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.preventDefault();
    if (selectedCategories.length > 0) {
      setLoading(true);

      let newCat,
        newSelectedCategories = [];
      selectedCategories.map((category) => {
        newCat = { _id: category };
        newSelectedCategories = [...newSelectedCategories, newCat];
      });

      const formDataImage = new FormData();
      const formDataThumbnail = new FormData();
      const formDataVideo = new FormData();
      const formDataStoryImage = new FormData();

      formDataImage.append("image", selectedImage);
      if (mediaType == "video") {
        if (!selectedThumbnail) {
          enqueueSnackbar("Please upload thumbnail image", {
            variant: "error",
          });
          setLoading(false);
          return;
        }
        formDataThumbnail.append("image", selectedThumbnail);
        formDataVideo.append("video", selectedVideo);
      } else if (mediaType == "image") {
        if (!storyImage) {
          enqueueSnackbar("Please upload image", { variant: "error" });
          setLoading(false);
          return;
        }
        formDataStoryImage.append("image", storyImage);
      }

      try {
        if (mediaType == "video") {
          const responseThumbnail = await upload_image_api(formDataThumbnail);
          console.log(responseThumbnail, "response image thumbnail");
          if (responseThumbnail.code == 200) {
            if (videoOption == "custom") {
              const responseVideo = await videoUploadApi(formDataVideo);
              console.log(responseVideo, " upload video scnz");
              if (responseVideo.code === 200) {
                try {
                  notificationTitle
                    ? notificationTitle
                    : setNotificationTitle(name);
                  notificationDescription
                    ? notificationDescription
                    : setNotificationDescription(description);
                  let story = "";
                  if (switchNotifications) {
                    story = {
                      title: name,
                      status: status,
                      story_type: "CUSTOM_VIDEO",
                      description: description,
                      video_url: responseVideo?.file?.path,
                      video_thumbnail: responseThumbnail.path,
                      story_categories: selectedCategories,
                      // is_sandbox: true,
                      // notification_status: switchNotifications,
                      notification_title: notificationSwitchTitle,
                      notification_description: message,
                      date: notificationDate,
                      languages: language,
                    };
                  } else {
                    story = {
                      title: name,
                      status: status,
                      story_type: "CUSTOM_VIDEO",
                      video_url: responseVideo?.file?.path,
                      description: description,
                      story_categories: selectedCategories,
                      video_thumbnail: responseThumbnail.path,
                      // is_sandbox: true,
                      // notification_status: switchNotifications,
                      date: notificationDate,
                      languages: language,
                    };
                  }
                  const res = await addStory(story);

                  console.log(res, "add api Result for .... ");
                  if (res.code == 200) {
                    funcTracks();
                    setCount(count + 1);
                    setNotificationDate("");
                    setNotificationDescription("");
                    setNotificationTitle("");
                    handleClose();
                    setOpenAddDrawer(false);
                    setImagePreview("");
                    setStoryImagePreview("");
                    setName("");
                    setVideothumbnail("");
                    setSelectedCategories([]);
                    setStoryImage("");
                    setSwitchNotifications(false);
                    setLanguage([]);
                    enqueueSnackbar(res.message, { variant: "success" });
                  } else {
                    enqueueSnackbar(res.message, { variant: "error" });
                  }
                } catch (error) {
                  console.log(error);
                  enqueueSnackbar("Could not add Story, Please try again.", {
                    variant: "error",
                  });
                }
              } else {
                enqueueSnackbar(responseVideo.message, { variant: "error" });
              }
            } else {
              try {
                notificationTitle
                  ? notificationTitle
                  : setNotificationTitle(name);
                notificationDescription
                  ? notificationDescription
                  : setNotificationDescription(description);
                let story = "";
                if (switchNotifications) {
                  story = {
                    title: name,
                    status: status,
                    story_type: "YOUTUBE_VIDEO",
                    description: description,
                    video_url: urlLink,
                    video_thumbnail: responseThumbnail.path,
                    story_categories: selectedCategories,
                    // is_sandbox: true,
                    // notification_status: switchNotifications,
                    notification_title: notificationSwitchTitle,
                    notification_description: message,
                    date: notificationDate,
                    languages: language,
                  };
                } else {
                  story = {
                    title: name,
                    status: status,
                    story_type: "YOUTUBE_VIDEO",
                    video_url: urlLink,
                    description: description,
                    story_categories: selectedCategories,
                    video_thumbnail: responseThumbnail.path,
                    // is_sandbox: true,
                    // notification_status: switchNotifications,
                    date: notificationDate,
                    languages: language,
                  };
                }
                const res = await addStory(story);

                console.log(res, "add api Result for .... ");
                if (res.code == 200) {
                  funcTracks();
                  setCount(count + 1);
                  setNotificationDate("");
                  setNotificationDescription("");
                  setNotificationTitle("");
                  handleClose();
                  setOpenAddDrawer(false);
                  setImagePreview("");
                  setStoryImagePreview("");
                  setName("");
                  setVideothumbnail("");
                  setLanguage([]);
                  setSelectedCategories([]);

                  setStoryImage("");
                  setSwitchNotifications(false);
                  enqueueSnackbar(res.message, { variant: "success" });
                } else {
                  enqueueSnackbar(res.message, { variant: "error" });
                }
              } catch (error) {
                console.log(error);
                enqueueSnackbar("Could not add Story, Please try again.", {
                  variant: "error",
                });
              }
            }
          } else {
            enqueueSnackbar(responseThumbnail.message, { variant: "error" });
          }
        } else if (mediaType == "image") {
          const responseStoryImage = await upload_image_api(formDataStoryImage);
          console.log(responseStoryImage, "response responseStoryImage");

          if (responseStoryImage.code == 200) {
            try {
              notificationTitle
                ? notificationTitle
                : setNotificationTitle(name);

              notificationDescription
                ? notificationDescription
                : setNotificationDescription(description);
              let story = "";
              if (switchNotifications) {
                story = {
                  title: name,
                  status: status,
                  story_type: "IMAGE",
                  image: responseStoryImage?.path ?? "",
                  description: description,
                  story_categories: selectedCategories,
                  date: notificationDate,

                  // is_sandbox: true,
                  // notification_status: switchNotifications,
                  notification_title: notificationSwitchTitle,
                  notification_description: message,
                  languages: language,
                };
              } else {
                story = {
                  title: name,
                  status: status,
                  story_type: "IMAGE",
                  image: responseStoryImage?.path ?? "",
                  description: description,
                  story_categories: selectedCategories,
                  // is_sandbox: true,
                  date: notificationDate,
                  // notification_status: switchNotifications,
                  languages: language,
                };
              }

              const res = await addStory(story);

              console.log(res, "add api Result for .... ");
              if (res.code == 200) {
                funcTracks();
                setCount(count + 1);
                setNotificationDate("");
                setNotificationDescription("");
                setNotificationTitle("");
                handleClose();
                setOpenAddDrawer(false);
                enqueueSnackbar(res.message, { variant: "success" });
              } else {
                enqueueSnackbar(res.message, { variant: "error" });
              }
            } catch (error) {
              console.log(error);
              enqueueSnackbar("Could not add Story, Please try again.", {
                variant: "error",
              });
            }
          } else {
            enqueueSnackbar("Please Upload Image.", { variant: "error" });
          }
        }
      } catch (error) {
        console.log(error);
        enqueueSnackbar("Could not Upload File(s), Please try again.", {
          variant: "error",
        });
      }
      setLoading(false);
    } else {
      enqueueSnackbar("Please select at least 1 category", {
        variant: "error",
      });
    }
  };

  const handleClose = () => {
    setOpenAddDrawer(false);
    setName();
    setVideoUrl("");
    setUrlLink("");
    setStatus(true);
    setSelectedImage("");
    setDescription();
    setSelectedCategories([]);
    setLoading(false);
    setImagePreview("");
    setSwitchNotifications(false);
    setStoryImage("");
    setSelectedVideo("");
    setThumbnailPreview("");
    setSelectedThumbnail("");
    setStoryImagePreview("");
    setMessage("");
    setNotificationSwitchTitle("");
    setLanguage([]);
  };

  useEffect(() => {
    if (openAddDrawer) {
      setNotificationDate(new Date(moment().add(5, "minutes")));
    }
  }, [openAddDrawer]);

  useEffect(() => {
    if (selectedImage) {
      setImagePreview(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  useEffect(() => {
    if (selectedThumbnail) {
      setThumbnailPreview(URL.createObjectURL(selectedThumbnail));
    }
  }, [selectedThumbnail]);

  useEffect(() => {
    if (storyImage) {
      setStoryImagePreview(URL.createObjectURL(storyImage));
    }
  }, [storyImage]);

  const handleNotifcationSwitch = (event) => {
    setSwitchNotifications(event.target.checked);
  };

  const handleVideoOptionChange = (event) => {
    setVideoOption(event.target.value);
    console.log(videoOption, " videoOption");

    //   if(videoOption == "custom"){
    //       setMediaType('video')
    //   }else{
    //       setMediaType('video_url')
    //   }
    //   console.log(mediaType, ' mediatype')
  };

  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={openAddDrawer}
      onClose={handleClose}
    >
      <Container sx={{ my: 10, width: 390 }}>
        <Typography variant="h4" sx={{ mt: -5, mb: 5 }}>
          Add new Story
        </Typography>
        <div className="mb-3">
          {checked && checked === true ? (
            <Alert severity="info" color="primary">
              <AlertTitle>Sandbox mode is on</AlertTitle>
              Notification scheduling is restricted to{" "}
              <strong>sandbox users</strong> only.
            </Alert>
          ) : (
            <Alert severity="error">
              <AlertTitle>Sandbox mode is off</AlertTitle>
              Notifications will be scheduled for <strong>all users.</strong>.
            </Alert>
          )}
        </div>
        <Alert severity="info" color="primary" sx={{ mb: 3 }}>
          <small>Default Language is English</small>
        </Alert>

        <form onSubmit={handleSubmit}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              onChange={(e) => setName(e.target.value)}
              required
              id="outlined-basic"
              label="Name"
              variant="outlined"
            />
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              onChange={(e) => setDescription(e.target.value)}
              required
              id="outlined-multiline-static"
              label="Description"
              multiline
              rows={4}
              defaultValue=""
            />
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="demo-simple-select-label" required>
              Categories
            </InputLabel>
            <Select
              required
              label="Categories"
              multiple
              value={selectedCategories}
              onChange={(e) => setSelectedCategories(e.target.value)}
              MenuProps={MenuProps}
            >
              {categories &&
                categories.map((category) => {
                  return (
                    <MenuItem key={category._id} value={category._id}>
                      {category.title}
                    </MenuItem>
                  );
                })}
              {!categories && "No category Found"}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="demo-simple-select-label">Languages</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Languages"
              value={language}
              className="text-capitalize"
              onChange={(e) => setLanguage(e.target.value)}
              multiple
            >
              {supportedLanguages?.map((name) => {
                return (
                  <MenuItem className="text-capitalize" value={name}>
                    {name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              required
              label="Status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              id="notification-switch-title"
              label="Notification Title "
              variant="outlined"
              value={notificationSwitchTitle}
              onChange={(e) => setNotificationSwitchTitle(e.target.value)}
            />
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              id="outlined-multiline-static"
              label="Notification Description"
              variant="outlined"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              multiline
              minRows={3} // Minimum number of rows
              maxRows={10} // Maximum number of rows
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <DatePickerField
              label="Schedule On*"
              value={notificationDate}
              onChange={(date) => setNotificationDate(date)}
            />
          </FormControl>

          {/* <Grid container spacing={2}>
              <Grid item xs={imagePreview ? 8 : 12}>
                <FormControl fullWidth sx={{ mb: 2, display: "block" }}>
                  <Button
                    variant="contained"
                    component="label"
                  >
                    {!selectedImage && (
                      <>
                        Select Image <PhotoCamera sx={{ ml: 1 }} />
                      </>
                    )}
                    {selectedImage && (
                      <>
                        <PhotoCamera sx={{ mr: 1 }} /> {selectedImage.name}
                      </>
                    )}
                    <input
                      hidden
                      name="image"
                      accept="image/jpeg, image/png , image/jpg , image/webp , image/HEIC"
                      type="file"
                      onChange={(e) => setSelectedImage(e.target.files[0])}
                    />
                  </Button>
                </FormControl>
              </Grid>
              {imagePreview && (
                <Grid item xs={4}>
                  <img src={imagePreview} alt="image" width="100" height="auto" />
                </Grid>
              )}
            </Grid> */}

          {/* Toggle Button for Media Type */}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <ToggleButtonGroup
              value={mediaType}
              exclusive
              onChange={handleMediaTypeChange}
              aria-label="media type"
            >
              <ToggleButton
                value="image"
                aria-label="image"
                style={{
                  backgroundColor: mediaType === "image" ? "#87c326" : "",
                  color: mediaType === "image" ? "white" : "#87c326",
                  borderRadius: "5px",
                  width: "200px",
                  height: "35px",
                  boxShadow: mediaType === "image" ? "0 4px 8px gray" : "none",
                }}
              >
                Image
              </ToggleButton>

              <ToggleButton
                value="video"
                aria-label="video"
                style={{
                  backgroundColor: mediaType === "video" ? "#87c326" : "",
                  color: mediaType === "video" ? "white" : "#87c326",
                  borderRadius: "5px",
                  width: "200px",
                  height: "35px",
                  boxShadow: mediaType === "video" ? "0 4px 8px gray" : "none",
                }}
              >
                Video
              </ToggleButton>
            </ToggleButtonGroup>
          </FormControl>
          {mediaType === "image" && (
            <Grid container spacing={2}>
              <Grid item xs={storyImage ? 8 : 12}>
                <FormControl fullWidth sx={{ mb: 2, display: "block" }}>
                  <Button
                    variant="contained"
                    component="label"
                    sx={{ marginTop: "" }}
                  >
                    {!storyImage && (
                      <>
                        Select Image <PhotoCamera sx={{ ml: 1 }} />
                      </>
                    )}
                    {storyImage && (
                      <>
                        <PhotoCamera sx={{ mr: 1 }} /> {storyImage.name}
                      </>
                    )}
                    <input
                      hidden
                      name="image"
                      accept="image/jpeg, image/png , image/jpg , image/webp , image/HEIC"
                      type="file"
                      onChange={(e) => setStoryImage(e.target.files[0])}
                    />
                  </Button>
                </FormControl>
                <small>
                  Recommended Image size for stories (1080 x 1920px) file type
                  (jpg,png)
                </small>
              </Grid>
              {storyImagePreview && (
                <Grid item xs={4} sx={{ marginTop: "60px" }}>
                  <img
                    src={storyImagePreview}
                    alt="image"
                    width="100"
                    height="auto"
                  />
                </Grid>
              )}
            </Grid>
          )}

          {mediaType === "video" && (
            <div>
              <FormControl fullWidth sx={{ mb: 0 }}>
                <InputLabel id="demo-simple-select-label">
                  Video Type
                </InputLabel>
                <Select
                  required
                  label="Video Type"
                  value={videoOption}
                  onChange={handleVideoOptionChange}
                >
                  <MenuItem value="custom">Custom Video</MenuItem>
                  <MenuItem value="url">URL Link</MenuItem>
                </Select>
              </FormControl>

              {videoOption === "custom" && (
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <Button
                    sx={{ marginTop: "20px" }}
                    variant="contained"
                    component="label"
                  >
                    {!selectedVideo && (
                      <>
                        Select Video <PhotoCamera sx={{ ml: 1 }} />
                      </>
                    )}
                    {selectedVideo && (
                      <>
                        <PhotoCamera sx={{ mr: 1 }} /> {selectedVideo.name}
                      </>
                    )}
                    <input
                      hidden
                      name="video"
                      accept="video/mp4, video/mkv, video/webm"
                      type="file"
                      onChange={(e) => setSelectedVideo(e.target.files[0])}
                    />
                  </Button>
                  <small style={{ marginTop: "15px" }}>
                    Recommended Video size for stories 1080 x 1920 px file type
                    (mp4)
                  </small>
                </FormControl>
              )}

              {videoOption === "url" && (
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <TextField
                    label="Video URL"
                    variant="outlined"
                    value={urlLink}
                    onChange={(e) => setUrlLink(e.target.value)}
                    fullWidth
                    sx={{ marginTop: "20px" }}
                  />
                </FormControl>
              )}
            </div>
          )}

          {mediaType === "video" && (
            <Grid container spacing={2}>
              <Grid item xs={thumbnailPreview ? 8 : 12}>
                <FormControl fullWidth sx={{ mb: 2, display: "block" }}>
                  <Button
                    sx={{ marginTop: "" }}
                    variant="contained"
                    component="label"
                  >
                    {!selectedThumbnail && (
                      <>
                        Video Thumbnail
                        <PhotoCamera sx={{ ml: 1 }} />
                      </>
                    )}
                    {selectedThumbnail && (
                      <>
                        <PhotoCamera sx={{ mr: 1 }} /> {selectedThumbnail.name}
                      </>
                    )}
                    <input
                      hidden
                      name="image"
                      accept="image/jpeg, image/png , image/jpg , image/webp , image/HEIC"
                      type="file"
                      onChange={(e) => setSelectedThumbnail(e.target.files[0])}
                    />
                  </Button>
                </FormControl>
                <small>
                  Recommended Video Thumbnail size (1080x1920) and file type
                  (jpg,png)
                </small>
              </Grid>

              {thumbnailPreview && (
                <Grid item xs={4} sx={{ marginTop: "70px" }}>
                  <img
                    src={thumbnailPreview}
                    alt="image"
                    width="100"
                    height="auto"
                  />
                </Grid>
              )}
            </Grid>
          )}

          {/* {switchNotifications && (
              <>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <TextField
                    id="notification-switch-title"
                    label="Notification Title "
                    variant="outlined"
                    value={notificationSwitchTitle}
                    onChange={(e) => setNotificationSwitchTitle(e.target.value)}
                    required
                  />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 2 }}>
                  <TextField
                    id="outlined-multiline-static"
                    label="Notification Message"
                    variant="outlined"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                    multiline
                    minRows={3} // Minimum number of rows
                    maxRows={10} // Maximum number of rows
                  />
                </FormControl>
              </>
            )} */}

          <LoadingButton loading={loading} type="submit" variant="contained">
            Save
          </LoadingButton>
        </form>
      </Container>
    </Drawer>
  );
}

export default AddTrackDrawer;
